import React from 'react';
import { motion } from 'framer-motion';

// Export the variants
export const boatVariants = {
  sailing: {
    y: [-4, 4, -4],
    rotate: [-3, 3, -3],
    transition: {
      duration: 4,
      repeat: Infinity,
      ease: "easeInOut"
    }
  },
  hover: {
    scale: 1.1,
    y: -8,
    transition: {
      duration: 0.3
    }
  }
};

const Boat = ({ size = 48, color = '#FFFFFF' }) => {
  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      initial={{ y: 0 }}
      animate={{ 
        y: [-4, 4, -4],
        rotate: [-3, 3, -3]
      }}
      transition={{ 
        duration: 4,
        repeat: Infinity,
        ease: "easeInOut"
      }}
    >
      <g fill={color}>
        {/* Larger, More Cartoonish Hull */}
        <motion.path 
          d="M2 15.5C2 15.5 8 14 12 14C16 14 22 15.5 22 15.5L19 20H5L2 15.5Z"
          initial={{ opacity: 0.8 }}
          animate={{ opacity: [0.8, 1, 0.8] }}
          transition={{ duration: 2, repeat: Infinity }}
        />
        
        {/* Larger Main Sail */}
        <motion.path 
          d="M12 3C12 3 8 8 7 14H12V3Z"
          initial={{ opacity: 0.9 }}
          animate={{ opacity: [0.9, 1, 0.9] }}
          transition={{ duration: 3, repeat: Infinity }}
        />
        
        {/* Larger Second Sail */}
        <motion.path 
          d="M12 3C12 3 16 8 17 14H12V3Z"
          initial={{ opacity: 0.85 }}
          animate={{ opacity: [0.85, 0.95, 0.85] }}
          transition={{ duration: 2.5, repeat: Infinity }}
        />
        
        {/* Thicker Mast */}
        <motion.rect 
          x="11.5" 
          y="3" 
          width="1" 
          height="11"
          initial={{ opacity: 0.9 }}
          animate={{ opacity: [0.9, 1, 0.9] }}
          transition={{ duration: 2, repeat: Infinity }}
        />

        {/* Decorative Flag */}
        <motion.path
          d="M11.5 3L13.5 2L11.5 1L12.5 3"
          initial={{ opacity: 0.7 }}
          animate={{ opacity: [0.7, 0.9, 0.7] }}
          transition={{ duration: 1.5, repeat: Infinity }}
        />
      </g>
      
      {/* Enhanced Water Reflection */}
      <motion.path
        d="M3 20.5C3 20.5 8 19 12 19C16 19 21 20.5 21 20.5"
        stroke={color}
        strokeOpacity={0.4}
        strokeWidth={0.5}
        strokeLinecap="round"
        strokeDasharray="0.5 1"
        fill="none"
        initial={{ opacity: 0.3 }}
        animate={{ opacity: [0.3, 0.5, 0.3] }}
        transition={{ duration: 2, repeat: Infinity }}
      />
    </motion.svg>
  );
};

export default Boat;
