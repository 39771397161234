import React, { useState, useRef } from 'react';
import { motion, AnimatePresence, useScroll, useTransform } from 'framer-motion';
import Navbar from '../../components/Navbar/Navbar';
import { Ship } from '../LandingPage/Ship';
import { Waves } from '../LandingPage/Waves';
import { Compass } from '../LandingPage/Compass';
import { Anchor, Users, Briefcase, Award, Target, Zap, Lightbulb, TrendingUp } from 'lucide-react';

const About = () => {
  const [activeSection, setActiveSection] = useState('story');
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start start", "end end"]
  });

  const sections = [
    { id: 'story', title: 'Our Story', icon: Anchor },
    { id: 'mission', title: 'Our Mission', icon: Target },
    { id: 'impact', title: 'Our Impact', icon: Zap },
    { id: 'future', title: 'Future Horizons', icon: TrendingUp },
  ];

  const sectionContent = {
    story: {
      title: "Charting New Waters",
      content: [
        "Our journey began in the halls of our university, where we found ourselves navigating the complex waters of career development.",
        "While academic clubs provided valuable experiences, we realized there was a vast ocean between classroom knowledge and real-world industry experience.",
        "Inspired by the guiding light of mentorship we had experienced, we envisioned a platform that could serve as a lighthouse for students - illuminating career paths and connecting them directly with seasoned professionals."
      ],
      image: "path/to/story-image.jpg" // Add relevant image path
    },
    mission: {
      title: "Setting the Course",
      content: [
        "At MentorShip, we believe that mentorship is the compass that guides students towards successful careers.",
        "Our mission is to create meaningful connections between students and industry professionals, fostering relationships that go beyond traditional networking.",
        "Through our platform, we're building stronger bridges between academia and industry, ensuring every student has access to expert guidance."
      ],
      image: "path/to/mission-image.jpg" // Add relevant image path
    },
    impact: {
      title: "Making Waves",
      content: [
        "Our impact extends across industries, creating ripples of change in how students approach their careers.",
        "Through MentorShip, countless students have found their true calling and charted successful career paths.",
        "We're not just connecting mentors and mentees - we're building the next generation of industry leaders."
      ],
      image: "path/to/impact-image.jpg" // Add relevant image path
    },
    future: {
      title: "Horizons Ahead",
      content: [
        "As we chart our course into the future, MentorShip is committed to expanding our reach and deepening our impact.",
        "We're developing innovative ways to connect mentors and mentees, leveraging cutting-edge technology to make mentorship more accessible and effective.",
        "Our vision is to create a global network of mentorship that transcends geographical boundaries and industry sectors."
      ],
      image: "path/to/future-image.jpg" // Add relevant image path
    }
  };

  const GraphicPattern = ({ pattern }) => {
    const patterns = {
      story: (
        <svg className="w-full h-full" viewBox="0 0 200 200">
          <defs>
            <linearGradient id="storyGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#60A5FA" stopOpacity="0.2" />
              <stop offset="100%" stopColor="#3B82F6" stopOpacity="0.3" />
            </linearGradient>
          </defs>
          <path
            d="M40,120 Q60,140 80,120 T120,120 T160,120"
            fill="none"
            stroke="url(#storyGradient)"
            strokeWidth="4"
            className="animate-draw"
          />
          <circle cx="100" cy="100" r="40" fill="none" stroke="#60A5FA" strokeWidth="2" className="animate-pulse-slow" />
          <path
            d="M100,60 L100,140 M60,100 L140,100"
            stroke="#3B82F6"
            strokeWidth="2"
            strokeDasharray="4,4"
            className="animate-rotate"
          />
        </svg>
      ),
      mission: (
        <svg className="w-full h-full" viewBox="0 0 200 200">
          <defs>
            <linearGradient id="missionGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#818CF8" stopOpacity="0.2" />
              <stop offset="100%" stopColor="#4F46E5" stopOpacity="0.3" />
            </linearGradient>
          </defs>
          <polygon
            points="100,20 180,100 100,180 20,100"
            fill="url(#missionGradient)"
            className="animate-spin-slow"
          />
          <circle cx="100" cy="100" r="30" fill="none" stroke="#818CF8" strokeWidth="2" />
          <path
            d="M100,70 L100,130 M70,100 L130,100"
            stroke="#4F46E5"
            strokeWidth="3"
            className="animate-pulse"
          />
        </svg>
      ),
      impact: (
        <svg className="w-full h-full" viewBox="0 0 200 200">
          <defs>
            <linearGradient id="impactGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#34D399" stopOpacity="0.2" />
              <stop offset="100%" stopColor="#059669" stopOpacity="0.3" />
            </linearGradient>
          </defs>
          {[...Array(6)].map((_, i) => (
            <circle
              key={i}
              cx="100"
              cy="100"
              r={20 + i * 15}
              fill="none"
              stroke="url(#impactGradient)"
              strokeWidth="1"
              className="animate-ripple"
              style={{ animationDelay: `${i * 0.5}s` }}
            />
          ))}
        </svg>
      ),
      future: (
        <svg className="w-full h-full" viewBox="0 0 200 200">
          <defs>
            <linearGradient id="futureGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#F472B6" stopOpacity="0.2" />
              <stop offset="100%" stopColor="#DB2777" stopOpacity="0.3" />
            </linearGradient>
          </defs>
          <path
            d="M20,100 Q60,20 100,100 T180,100"
            fill="none"
            stroke="url(#futureGradient)"
            strokeWidth="3"
            className="animate-draw"
          />
          {[...Array(3)].map((_, i) => (
            <path
              key={i}
              d={`M${80 + i * 20},180 L100,20`}
              stroke="#F472B6"
              strokeWidth="1"
              strokeDasharray="4,4"
              className="animate-dash"
              style={{ animationDelay: `${i * 0.3}s` }}
            />
          ))}
        </svg>
      ),
    };

    return patterns[pattern] || null;
  };

  return (
    <div className="relative min-h-screen bg-gradient-to-b from-blue-950 via-blue-800 to-blue-600 overflow-hidden">
      <style jsx>{`
        @keyframes float {
          0%, 100% { transform: translate(0, 0) rotate(0deg); }
          25% { transform: translate(10px, -10px) rotate(2deg); }
          75% { transform: translate(-10px, -10px) rotate(-2deg); }
        }

        @keyframes wave {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-10px); }
        }

        .floating {
          animation: float 8s infinite ease-in-out;
        }

        .waving {
          animation: wave 6s infinite ease-in-out;
        }

        .gradient-text {
          background: linear-gradient(135deg, #60A5FA, #3B82F6);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .glass-card {
          background: rgba(255, 255, 255, 0.1);
          backdrop-filter: blur(10px);
          border: 1px solid rgba(255, 255, 255, 0.2);
        }

        @keyframes draw {
          from { stroke-dashoffset: 1000; }
          to { stroke-dashoffset: 0; }
        }

        @keyframes ripple {
          0% { transform: scale(0.8); opacity: 1; }
          100% { transform: scale(1.2); opacity: 0; }
        }

        @keyframes rotate {
          from { transform: rotate(0deg); }
          to { transform: rotate(360deg); }
        }

        @keyframes dash {
          to { stroke-dashoffset: -1000; }
        }

        .animate-draw {
          stroke-dasharray: 1000;
          stroke-dashoffset: 1000;
          animation: draw 3s ease-out forwards;
        }

        .animate-ripple {
          animation: ripple 3s infinite;
        }

        .animate-rotate {
          transform-origin: center;
          animation: rotate 20s linear infinite;
        }

        .animate-spin-slow {
          transform-origin: center;
          animation: rotate 30s linear infinite;
        }

        .animate-dash {
          stroke-dasharray: 4;
          animation: dash 20s linear infinite;
        }

        .animate-pulse-slow {
          animation: pulse 4s ease-in-out infinite;
        }
      `}</style>

      <Navbar />

      {/* Animated Background Elements */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <Waves className="absolute inset-x-0 bottom-0 z-0 opacity-20 waving" />
        <Ship className="absolute top-[10%] left-[5%] z-10 opacity-30 w-16 h-16 md:w-24 md:h-24 floating" />
        <Ship className="absolute top-[30%] right-[15%] z-10 opacity-20 w-12 h-12 md:w-20 md:h-20 floating" />
        <Compass className="absolute bottom-[20%] right-[10%] z-10 opacity-30 w-16 h-16 md:w-24 md:h-24" />
      </div>

      <div ref={containerRef} className="container mx-auto px-4 pt-32 pb-20 relative z-10">
        <motion.h1 
          className="text-4xl md:text-6xl font-bold mb-8 text-center gradient-text"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          The MentorShip Journey
        </motion.h1>

        {/* Navigation Tabs */}
        <motion.div 
          className="flex flex-wrap justify-center gap-4 mb-12"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          {sections.map((section) => (
            <motion.button
              key={section.id}
              className={`flex items-center px-6 py-3 rounded-full transition-all duration-300 ${
                activeSection === section.id 
                  ? 'bg-white text-blue-900 shadow-lg' 
                  : 'bg-white/10 text-white hover:bg-white/20'
              }`}
              onClick={() => setActiveSection(section.id)}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <section.icon className={`w-5 h-5 mr-2 ${
                activeSection === section.id ? 'text-blue-900' : 'text-white'
              }`} />
              {section.title}
            </motion.button>
          ))}
        </motion.div>

        {/* Content Section */}
        <AnimatePresence mode="wait">
          <motion.div
            key={activeSection}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            className="glass-card rounded-2xl p-8 md:p-12 shadow-xl"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <motion.h2 
                  className="text-3xl font-bold mb-8 text-white"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.3 }}
                >
                  {sectionContent[activeSection].title}
                </motion.h2>

                <div className="space-y-6">
                  {sectionContent[activeSection].content.map((paragraph, index) => (
                    <motion.p
                      key={index}
                      className="text-lg text-blue-100 leading-relaxed"
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.4 + index * 0.1 }}
                    >
                      {paragraph}
                    </motion.p>
                  ))}
                </div>
              </div>

              <motion.div
                className="relative h-[400px] flex items-center justify-center"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.5 }}
              >
                <div className="w-full h-full max-w-[400px] max-h-[400px]">
                  <GraphicPattern pattern={activeSection} />
                </div>
              </motion.div>
            </div>

            {/* Interactive Elements */}
            <motion.div
              className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-6"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.7 }}
            >
              {[
                { icon: Users, text: "Connect with Industry Leaders" },
                { icon: Briefcase, text: "Gain Real-World Experience" },
                { icon: Lightbulb, text: "Discover Your Path" }
              ].map((item, index) => (
                <motion.div
                  key={index}
                  className="bg-white/5 rounded-xl p-6 text-center hover:bg-white/10 transition-all duration-300"
                  whileHover={{ scale: 1.05, y: -5 }}
                >
                  <item.icon className="w-8 h-8 mx-auto mb-4 text-blue-300" />
                  <p className="text-white">{item.text}</p>
                </motion.div>
              ))}
            </motion.div>
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default About;
