import React from 'react'
import { motion } from 'framer-motion'

export function Waves({ className = '' }) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <motion.path 
        fill="#0099ff" 
        fillOpacity="0.4"
        d="M0,160L48,80C96,0,192,160,288,120C384,80,480,0,576,40C672,80,768,200,864,160C960,120,1056,0,1152,40C1248,80,1344,200,1392,160L1440,120L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        animate={{
          d: [
            "M0,160L48,80C96,0,192,160,288,120C384,80,480,0,576,40C672,80,768,200,864,160C960,120,1056,0,1152,40C1248,80,1344,200,1392,160L1440,120L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z",
            "M0,128L48,48C96,-32,192,128,288,88C384,48,480,-32,576,8C672,48,768,168,864,128C960,88,1056,-32,1152,8C1248,48,1344,168,1392,128L1440,88L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z",
          ],
        }}
        transition={{
          repeat: Infinity,
          repeatType: "reverse",
          duration: 10,
          ease: "easeInOut",
        }}
      />
    </svg>
  )
}
