import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { motion, useViewportScroll, useTransform, AnimatePresence } from 'framer-motion'
import { Waves } from './Waves'
import { Ship } from './Ship'
import { Compass } from './Compass'
import Navbar from '../../components/Navbar/Navbar'
import { Anchor, Zap, Users, Target, ArrowRight } from 'lucide-react'
import Boat, { boatVariants } from './Boat'

// Number of boats you want to display
const NUMBER_OF_BOATS = 10;

// Function to generate random positions
const generateRandomPositions = (count) => {
  const positions = [];
  for (let i = 0; i < count; i++) {
    positions.push({
      top: `${Math.random() * 100}%`,
      left: `${Math.random() * 100}%`,
      size: 16 + Math.random() * 24, // Random size between 16 and 40
      color: ['white', 'blue-200', 'blue-300', 'yellow-400'][Math.floor(Math.random() * 4)]
    });
  }
  return positions;
}

const WavePattern = () => (
  <svg className="absolute inset-0 w-full h-full opacity-10" viewBox="0 0 100 100" preserveAspectRatio="none">
    <motion.path
      d="M0,50 C20,60 40,40 60,50 C80,60 100,40 120,50"
      stroke="currentColor"
      strokeWidth="0.5"
      fill="none"
      className="text-blue-300"
      initial={{ pathLength: 0 }}
      animate={{ pathLength: 1 }}
      transition={{ duration: 2, repeat: Infinity, repeatType: "reverse" }}
    />
    <motion.path
      d="M0,55 C20,65 40,45 60,55 C80,65 100,45 120,55"
      stroke="currentColor"
      strokeWidth="0.5"
      fill="none"
      className="text-blue-200"
      initial={{ pathLength: 0 }}
      animate={{ pathLength: 1 }}
      transition={{ duration: 2.5, repeat: Infinity, repeatType: "reverse", delay: 0.2 }}
    />
  </svg>
)

const FloatingShips = () => (
  <>
    <motion.div 
      className="absolute z-10 opacity-50"
      animate={{
        x: ['-10%', '110%'],
        y: [-40, -120, -40],
      }}
      transition={{
        duration: 20,
        repeat: Infinity,
        ease: "linear",
      }}
    >
      <Ship className="w-32 h-32 transform -rotate-12" />
    </motion.div>
    <motion.div 
      className="absolute z-10 opacity-30"
      animate={{
        x: ['110%', '-10%'],
        y: [-80, -160, -80],
      }}
      transition={{
        duration: 25,
        repeat: Infinity,
        ease: "linear",
        delay: 5,
      }}
    >
      <Ship className="w-24 h-24 transform rotate-12" />
    </motion.div>
  </>
)

const StarField = () => (
  <div className="absolute inset-0 overflow-hidden">
    {[...Array(50)].map((_, i) => (
      <motion.div
        key={i}
        className="absolute w-1 h-1 bg-white rounded-full"
        style={{
          left: `${Math.random() * 100}%`,
          top: `${Math.random() * 100}%`,
        }}
        animate={{
          opacity: [0, 1, 0],
          scale: [0, 1, 0],
        }}
        transition={{
          duration: 2 + Math.random() * 3,
          repeat: Infinity,
          delay: Math.random() * 2,
        }}
      />
    ))}
  </div>
)

export default function Home() {
  const { scrollYProgress } = useViewportScroll()
  const opacity = useTransform(scrollYProgress, [0, 0.5], [1, 0])
  const scale = useTransform(scrollYProgress, [0, 0.5], [1, 0.8])
  const [hoveredFeature, setHoveredFeature] = useState(null)
  
  // State to hold boat positions
  const [boatPositions] = useState(() => {
    const positions = [];
    const numberOfBoats = 8; // Reduced for better spacing
    
    // Define safe zones (areas to avoid)
    const safeZones = [
      // Header area (top center)
      { top: 0, bottom: 30, left: 20, right: 80 },
      // Main content area (middle center)
      { top: 40, bottom: 70, left: 25, right: 75 },
      // Button area
      { top: 65, bottom: 80, left: 35, right: 65 }
    ];

    // Helper function to check if position overlaps with safe zones
    const isPositionSafe = (top, left) => {
      return !safeZones.some(zone => 
        top >= zone.top && top <= zone.bottom &&
        left >= zone.left && left <= zone.right
      );
    };

    // Create grid-like positions with some randomness
    const gridRows = 4;
    const gridCols = 3;
    
    for (let row = 0; row < gridRows; row++) {
      for (let col = 0; col < gridCols; col++) {
        // Base position in grid
        let baseTop = (row * 100) / gridRows;
        let baseLeft = (col * 100) / gridCols;
        
        // Add controlled randomness
        let top = baseTop + (Math.random() * 15 - 7.5);
        let left = baseLeft + (Math.random() * 15 - 7.5);
        
        // Only add position if it's in a safe zone
        if (isPositionSafe(top, left)) {
          positions.push({
            top: `${top}%`,
            left: `${left}%`,
            size: 48 + Math.random() * 24, // Slightly reduced size range
            color: `rgba(255, 255, 255, ${0.4 + Math.random() * 0.3})`,
            delay: Math.random() * 2,
            duration: 4 + Math.random() * 2,
            scale: 0.9 + Math.random() * 0.2 // More consistent scaling
          });
        }
      }
    }

    // Sort by size for proper layering
    return positions.sort((a, b) => a.size - b.size);
  });

  return (
    <div className="relative min-h-screen bg-gradient-to-b from-blue-950 via-blue-800 to-blue-600 overflow-hidden font-sans">
      <style jsx>{`
        @keyframes float {
          0%, 100% { transform: translateY(0px); }
          50% { transform: translateY(-20px); }
        }

        @keyframes pulse {
          0%, 100% { opacity: 0.6; transform: scale(1); }
          50% { opacity: 1; transform: scale(1.05); }
        }

        .floating {
          animation: float 6s ease-in-out infinite;
        }

        .pulsing {
          animation: pulse 4s ease-in-out infinite;
        }

        .glass-card {
          background: rgba(255, 255, 255, 0.1);
          backdrop-filter: blur(10px);
          border: 1px solid rgba(255, 255, 255, 0.2);
        }

        /* New styles for boats */
        .boat {
          position: absolute;
          transform: translate(-50%, -50%);
          pointer-events: none; /* Allows clicks to pass through */
        }

        .boat-container {
          pointer-events: none;
          mix-blend-mode: soft-light;
          position: absolute;
          width: 100%;
          height: 100%;
          overflow: hidden;
          z-index: 1; // Ensure boats stay behind content
        }
        
        @media (max-width: 768px) {
          .boat-container {
            display: none;
          }
        }
      `}</style>

      <StarField />
      <Waves className="absolute inset-x-0 bottom-0 z-0" />
      <WavePattern />
      <FloatingShips />

      {/* Render Boats */}
      <div className="boat-container">
        {boatPositions.map((boat, index) => (
          <motion.div
            key={index}
            className="absolute"
            style={{ 
              top: boat.top, 
              left: boat.left,
              transform: 'translate(-50%, -50%)',
            }}
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: boat.scale }}
            transition={{ 
              duration: 0.8, 
              delay: boat.delay,
              ease: "easeOut"
            }}
            whileHover="hover"
            variants={boatVariants}
          >
            <Boat 
              size={boat.size} 
              color={boat.color}
            />
          </motion.div>
        ))}
      </div>

      <Navbar />

      <main className="container mx-auto px-4 pt-24 pb-20 relative z-10">
        <motion.div
          className="text-center mb-16"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <motion.h1 
            className="text-5xl md:text-7xl font-black text-white mb-8 leading-tight"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Navigate Your Future with{' '}
            <span className="relative inline-block">
              <span className="relative z-10 bg-gradient-to-r from-yellow-400 to-yellow-600 text-transparent bg-clip-text">
                Mentorsh<span className="relative inline-block text-yellow-400">ı<Compass className="absolute -top-1 -right-.5 w-5 h-5 md:w-6 md:h-6 text-yellow-300 pulsing" /></span>p
              </span>
            </span>
          </motion.h1>
          
          <motion.p 
            className="text-xl md:text-2xl text-blue-100 mb-12 font-medium tracking-wide"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.8 }}
          >
            Embark on a journey of growth with industry leaders as your guides
          </motion.p>
          
          <motion.div 
            className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4 mb-20"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.8, duration: 0.8 }}
          >
            <Link 
              to="/signup" 
              className="group relative inline-flex items-center justify-center px-8 py-4 text-lg font-bold text-white bg-gradient-to-r from-blue-600 to-blue-700 rounded-full overflow-hidden transition-all duration-300 ease-out hover:from-blue-700 hover:to-blue-800 transform hover:-translate-y-1"
            >
              <span className="absolute inset-0 w-full h-full bg-gradient-to-r from-blue-400 to-blue-500 opacity-0 group-hover:opacity-20 transition-opacity duration-300 ease-out" />
              <Anchor className="w-5 h-5 mr-2" />
              <span className="relative">Start Your Journey</span>
            </Link>
            <Link 
              to="/about" 
              className="group relative inline-flex items-center justify-center px-8 py-4 text-lg font-bold text-blue-100 border-2 border-blue-100/30 rounded-full overflow-hidden transition-all duration-300 ease-out hover:border-blue-100/50 hover:bg-white/5 transform hover:-translate-y-1"
            >
              <Target className="w-5 h-5 mr-2" />
              <span>Learn More</span>
            </Link>
          </motion.div>

          <motion.div 
            className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-20"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.2, duration: 0.8 }}
          >
            {[
              {
                title: "Connect with Mentors",
                description: "Find your perfect match among industry professionals who share your passion and goals.",
                icon: Users,
                color: "from-purple-400 to-purple-600"
              },
              {
                title: "Accelerate Growth",
                description: "Fast-track your career development with personalized guidance and industry insights.",
                icon: Zap,
                color: "from-yellow-400 to-yellow-600"
              },
              {
                title: "Chart Your Course",
                description: "Define and navigate your career path with confidence and expert support.",
                icon: Target,
                color: "from-green-400 to-green-600"
              }
            ].map((feature, index) => (
              <motion.div
                key={index}
                className="glass-card rounded-2xl p-8 relative overflow-hidden group"
                whileHover={{ y: -10 }}
                onHoverStart={() => setHoveredFeature(index)}
                onHoverEnd={() => setHoveredFeature(null)}
              >
                <motion.div
                  className={`absolute inset-0 bg-gradient-to-br ${feature.color} opacity-0 group-hover:opacity-10 transition-opacity duration-300`}
                />
                <feature.icon className="w-12 h-12 mb-6 text-white" />
                <h3 className="text-2xl font-bold text-white mb-4">{feature.title}</h3>
                <p className="text-blue-100">{feature.description}</p>
                <motion.div
                  className="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-transparent via-white to-transparent"
                  initial={{ scaleX: 0, opacity: 0 }}
                  animate={{ 
                    scaleX: hoveredFeature === index ? 1 : 0,
                    opacity: hoveredFeature === index ? 0.5 : 0
                  }}
                  transition={{ duration: 0.3 }}
                />
              </motion.div>
            ))}
          </motion.div>

          <motion.div
            className="text-center"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.6, duration: 0.8 }}
          >
            <Link
              to="/companymentors"
              className="inline-flex items-center text-white hover:text-yellow-400 transition-colors duration-300"
            >
              <span className="text-xl font-semibold">Explore Our Network</span>
              <ArrowRight className="w-6 h-6 ml-2" />
            </Link>
          </motion.div>
        </motion.div>
      </main>
    </div>
  )
}
