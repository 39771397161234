import React, { useState, useContext } from "react";
import { Spinner } from "../../components";
import { AuthContext } from "../../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaGraduationCap, FaUniversity } from "react-icons/fa";
import { MdPerson, MdEmail, MdPhone, MdLock } from "react-icons/md";
import { motion, AnimatePresence } from 'framer-motion';
import { Ship } from "../LandingPage/Ship";
import { Waves } from "../LandingPage/Waves";
import { Anchor, Compass, Map } from 'lucide-react';
import Navbar from '../../components/Navbar/Navbar';

const Signup = () => {
  const [fullName, setFullName] = useState("");
  const [school, setSchool] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [major, setMajor] = useState("");
  const [gender, setGender] = useState("");
  const [otherGender, setOtherGender] = useState("");
  const [graduationDate, setGraduationDate] = useState("");
  const [passwordStrength, setPasswordStrength] = useState("");

  const { signup, isFetching, isLoading } = useContext(AuthContext);
  const navigate = useNavigate();

  const isDisabled = isLoading || !fullName || !school || !phoneNumber || !email || !password || !major || !gender || !graduationDate || passwordStrength !== "strong";

  const checkPasswordStrength = (password) => {
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
    const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

    if (strongRegex.test(password)) {
      setPasswordStrength("strong");
    } else if (mediumRegex.test(password)) {
      setPasswordStrength("medium");
    } else {
      setPasswordStrength("weak");
    }
  };

  const getPasswordFeedback = (password) => {
    const feedback = [];
    if (!/(?=.*[a-z])/.test(password)) feedback.push("lowercase letter");
    if (!/(?=.*[A-Z])/.test(password)) feedback.push("uppercase letter");
    if (!/(?=.*[0-9])/.test(password)) feedback.push("number");
    if (!/(?=.*[!@#$%^&*])/.test(password)) feedback.push("special character");
    if (password.length < 8) feedback.push("at least 8 characters");
    return feedback;
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    checkPasswordStrength(newPassword);
  };

  const handlePhoneNumberChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    setPhoneNumber(numericValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Determine the final gender value
    let finalGender;
    if (gender === "other") {
      finalGender = otherGender.trim() || "Other";
    } else {
      finalGender = gender;
    }
    
    // Create an object with all the user data
    const userData = {
      role: "Applicant",
      fullName: fullName.trim(),
      school: school.trim(),
      phoneNumber: phoneNumber.trim(),
      email: email.trim(),
      major: major.trim(),
      gender: finalGender,
      graduationDate,
      profilePhoto: "",
      completedQuestionnaire: false,
    };

    // Remove any undefined or empty string values
    Object.keys(userData).forEach(key => 
      (userData[key] === undefined || userData[key] === "") && delete userData[key]
    );

    console.log('User data being sent:', userData); // For debugging

    const res = await signup(email, password, userData);
    if (res.error) {
      toast.error(res.error);
    } else {
      toast.success("Signup successful!");
      navigate('/dashboard');
    }
  };

  const handleClear = () => {
    setFullName("");
    setSchool("");
    setPhoneNumber("");
    setEmail("");
    setPassword("");
    setMajor("");
    setGender("");
    setOtherGender("");
    setGraduationDate("");
    setPasswordStrength("");
  };

  const handleNameChange = (e) => {
    const formattedName = e.target.value
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    setFullName(formattedName);
  };

  // Define handlers for each field
  const handleSchoolChange = (e) => setSchool(e.target.value);
  const handleMajorChange = (e) => setMajor(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleGraduationDateChange = (e) => setGraduationDate(e.target.value);

  const formSections = [
    {
      title: "Personal Information",
      icon: <MdPerson className="text-blue-600 w-6 h-6" />,
      fields: [
        { name: "fullName", label: "Full Name", type: "text", value: fullName, onChange: handleNameChange, icon: <MdPerson /> },
        { name: "gender", label: "Gender", type: "select", value: gender, onChange: setGender, options: [
          { value: "", label: "Select Gender" },
          { value: "male", label: "Male" },
          { value: "female", label: "Female" },
          { value: "other", label: "Other" }
        ]},
        { name: "otherGender", label: "Specify Gender", type: "text", value: otherGender, onChange: (e) => setOtherGender(e.target.value), condition: gender === "other" },
        { name: "phoneNumber", label: "Phone Number", type: "tel", value: phoneNumber, onChange: handlePhoneNumberChange, icon: <MdPhone />, maxLength: 10, inputMode: "numeric", pattern: "[0-9]*" }
      ]
    },
    {
      title: "Academic Details",
      icon: <FaGraduationCap className="text-blue-600 w-6 h-6" />,
      fields: [
        { name: "school", label: "School", type: "text", value: school, onChange: handleSchoolChange, icon: <FaUniversity /> },
        { name: "major", label: "Major", type: "text", value: major, onChange: handleMajorChange, icon: <FaGraduationCap /> },
        { name: "graduationDate", label: "Graduation Date", type: "date", value: graduationDate, onChange: handleGraduationDateChange }
      ]
    },
    {
      title: "Account Security",
      icon: <Anchor className="text-blue-600 w-6 h-6" />,
      fields: [
        { name: "email", label: "Email", type: "email", value: email, onChange: handleEmailChange, icon: <MdEmail /> },
        { name: "password", label: "Password", type: "password", value: password, onChange: handlePasswordChange, icon: <MdLock /> }
      ]
    }
  ];

  if (isFetching) return <Spinner />;

  return (
    <div className="relative min-h-screen bg-gradient-to-b from-blue-50 via-blue-100 to-blue-200 overflow-hidden font-sans">
      <Navbar />
      <div className="absolute inset-0 bg-grid-pattern opacity-5"></div>
      <Waves className="absolute inset-x-0 bottom-0 z-0 opacity-20" />
      
      <div className="flex flex-col justify-center items-center min-h-screen px-4 z-30 pt-24">
        <motion.div 
          className="w-full max-w-2xl rounded-2xl shadow-xl z-30 bg-white/95 backdrop-blur-lg p-8"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <div className="flex items-center justify-center mb-8">
            <motion.div
              initial={{ rotate: 0 }}
              animate={{ rotate: 360 }}
              transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
            >
              <Compass className="w-12 h-12 text-blue-600" />
            </motion.div>
            <h2 className="text-4xl font-bold text-blue-900 ml-4">Begin Your Journey</h2>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            {formSections.map((section, index) => (
              <motion.div
                key={section.title}
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.2 }}
                className="bg-blue-50/50 rounded-xl p-6"
              >
                <div className="flex items-center mb-4">
                  {section.icon}
                  <h3 className="text-xl font-semibold text-blue-800 ml-2">{section.title}</h3>
                </div>
                
                <div className="space-y-4">
                  {section.fields.map((field) => (
                    field.condition !== false && (
                      <div key={field.name} className="relative">
                        <label htmlFor={field.name} className="block text-sm font-medium text-gray-700 mb-1">
                          {field.label}
                        </label>
                        {field.type === "select" ? (
                          <select
                            id={field.name}
                            name={field.name}
                            value={field.value}
                            onChange={(e) => field.onChange(e.target.value)}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                          >
                            {field.options.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <div className="relative">
                            {field.icon && (
                              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                {field.icon}
                              </div>
                            )}
                            <input
                              type={field.type}
                              id={field.name}
                              name={field.name}
                              value={field.value}
                              onChange={field.onChange}
                              className={`mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${field.icon ? 'pl-10' : ''}`}
                              maxLength={field.maxLength}
                              inputMode={field.inputMode}
                              pattern={field.pattern}
                              style={{
                                padding: field.icon ? '12px 16px 12px 40px' : '12px 16px',
                                marginBottom: '8px',
                              }}
                            />
                          </div>
                        )}
                      </div>
                    )
                  ))}
                </div>
              </motion.div>
            ))}

            {/* Password strength indicator with animation and feedback */}
            <AnimatePresence>
              {password && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: "auto", opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  className="bg-blue-50 rounded-lg p-4"
                >
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-sm font-medium">Password Strength</span>
                    <motion.div 
                      className={`h-2 w-24 rounded-full ${
                        passwordStrength === "strong" ? "bg-green-500" :
                        passwordStrength === "medium" ? "bg-yellow-500" :
                        "bg-red-500"
                      }`}
                      initial={{ width: 0 }}
                      animate={{ 
                        width: passwordStrength === "strong" ? 96 :
                               passwordStrength === "medium" ? 48 : 24
                      }}
                    />
                  </div>
                  {passwordStrength !== "strong" && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className="text-sm text-gray-600"
                    >
                      Your password is missing:
                      <ul className="list-disc list-inside mt-1">
                        {getPasswordFeedback(password).map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </motion.div>
                  )}
                </motion.div>
              )}
            </AnimatePresence>

            {/* Action Buttons */}
            <div className="flex space-x-4 pt-6">
              <motion.button
                whileHover={{ scale: 1.02, boxShadow: "0 5px 15px rgba(0,0,0,0.1)" }}
                whileTap={{ scale: 0.98 }}
                disabled={isDisabled}
                type="submit"
                className="flex-1 bg-blue-600 text-white py-4 rounded-xl text-lg font-semibold hover:bg-blue-700 transition-all duration-300 disabled:bg-blue-400 disabled:cursor-not-allowed flex items-center justify-center"
              >
                {!isLoading ? (
                  <>
                    <Map className="w-5 h-5 mr-2" />
                    Chart Your Course
                  </>
                ) : (
                  <Spinner />
                )}
              </motion.button>
              
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={handleClear}
                type="reset"
                className="px-6 border-2 border-blue-600 text-blue-600 py-4 rounded-xl text-lg font-semibold hover:bg-blue-50 transition-all duration-300"
              >
                Clear
              </motion.button>
            </div>
            <p className="text-center text-sm text-gray-600 mt-6">
            </p>
          </form>
        </motion.div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Signup;
