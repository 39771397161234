import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Compass } from '../../pages/LandingPage/Compass';
import { AuthContext } from '../../contexts/AuthContext';
import { LogOut, LayoutDashboard, Info, Building2 } from 'lucide-react';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { currentUser, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate('/signin');
  };

  return (
    <header className="bg-white/80 backdrop-blur-md shadow-md fixed top-0 left-0 right-0 z-50">
      <nav className="container mx-auto px-4 py-4 flex justify-between items-center">
        <Link to="/" className="text-3xl font-extrabold text-blue-600 flex items-center">
          MentorSh<span className="relative">
            ı
            <Compass className="absolute -top-1 -right-0 w-3.5 h-3.5 text-yellow-500" />
          </span>p
        </Link>
        
        {/* Hamburger Menu Button */}
        <div className="md:hidden">
          <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="text-blue-600">
            {isMenuOpen ? (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8h16M4 16h16" />
              </svg>
            )}
          </button>
        </div>

        {/* Navigation Links */}
        <ul className={`md:flex items-center space-x-4 ${isMenuOpen ? 'block absolute top-full left-0 right-0 bg-white/80 backdrop-blur-md p-4' : 'hidden'} md:relative md:bg-transparent md:p-0`}>
          <li>
            <Link 
              to="/about" 
              className="flex items-center space-x-2 px-4 py-2 rounded-lg text-blue-600 hover:bg-blue-50 transition-colors duration-200"
            >
              <Info className="w-4 h-4" />
              <span>About</span>
            </Link>
          </li>
          <li>
            <Link 
              to="/companymentors" 
              className="flex items-center space-x-2 px-4 py-2 rounded-lg text-blue-600 hover:bg-blue-50 transition-colors duration-200"
            >
              <Building2 className="w-4 h-4" />
              <span>Company Mentors</span>
            </Link>
          </li>
          
          {currentUser ? (
            <>
              <li>
                <Link 
                  to="/dashboard" 
                  className="flex items-center space-x-2 px-4 py-2 text-white bg-blue-600 hover:bg-blue-700 rounded-lg transition-colors duration-200"
                >
                  <LayoutDashboard className="w-4 h-4" />
                  <span>Dashboard</span>
                </Link>
              </li>
              <li>
                <button 
                  onClick={handleLogout}
                  className="flex items-center space-x-2 px-4 py-2 text-red-600 hover:bg-red-50 rounded-lg transition-colors duration-200"
                >
                  <LogOut className="w-4 h-4" />
                  <span>Logout</span>
                </button>
              </li>
            </>
          ) : (
            <li>
              <Link 
                to="/signin" 
                className="flex items-center space-x-2 px-4 py-2 text-white bg-blue-600 hover:bg-blue-700 rounded-lg transition-colors duration-200"
              >
                Join
              </Link>
            </li>
          )}
        </ul>
      </nav>
    </header>
  );
}

export default Navbar;
