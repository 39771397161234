import { motion } from 'framer-motion'

// Remove TypeScript interface
// interface CompassProps {
//   className?: string
//   style?: React.CSSProperties
// }

export function Compass({ className = '', style = {} }) {
  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`w-16 h-16 text-blue-800 ${className}`}
      style={style}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
    >
      <circle cx="12" cy="12" r="10" />
      <polygon points="16.24 7.76 14.12 14.12 7.76 16.24 9.88 9.88 16.24 7.76" />
    </motion.svg>
  )
}