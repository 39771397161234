import React, { useState, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import Navbar from '../../components/Navbar/Navbar';
import { Ship } from '../LandingPage/Ship';
import { Waves } from '../LandingPage/Waves';
import { Compass } from '../LandingPage/Compass';
import { Anchor, Users, Briefcase, Award, MapPin } from 'lucide-react';

const companies = [
  // Most popular tech companies
  { 
    name: 'Google', 
    logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/480px-Google_%22G%22_logo.svg.png', 
    industry: 'Technology',
    description: 'A technology giant known for its search engine, advertising services, and innovations in AI, cloud computing, and consumer electronics.',
    expertise: ['Search Algorithms', 'Cloud Computing', 'AI/ML', 'Advertising Technology']
  },
  { 
    name: 'Microsoft', 
    logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Microsoft_logo.svg/2048px-Microsoft_logo.svg.png', 
    industry: 'Technology',
    description: 'A leading technology company developing software, hardware, and services that empower individuals and organizations worldwide.',
    expertise: ['Software Development', 'Cloud Services', 'AI/ML', 'Enterprise Solutions']
  },
  // Major financial and consulting firms
  { 
    name: 'Deloitte', 
    logo: 'https://1000logos.net/wp-content/uploads/2019/08/Deloitte-Logo.jpg', 
    industry: 'Consulting',
    description: 'A global consulting firm offering audit, tax, consulting, and advisory services to help organizations solve complex business challenges.',
    expertise: ['Management Consulting', 'Financial Advisory', 'Risk Management', 'Technology Consulting']
  },
  { 
    name: 'Bank of America', 
    logo: 'https://www.logo-designer.co/storage/2018/11/2018-bank-of-america-reveals-new-logo-design-by-lippincott-3.png', 
    industry: 'Finance',
    description: 'A leading financial institution providing innovative banking solutions and investment services globally.',
    expertise: ['FinTech', 'Software Development', 'Data Analytics', 'Cybersecurity']
  },
  { 
    name: 'Vanguard', 
    logo: 'https://1000logos.net/wp-content/uploads/2021/05/Vanguard-logo.png', 
    industry: 'Finance',
    description: 'An investment management company known for its low-cost mutual funds and ETFs, helping investors achieve their financial goals.',
    expertise: ['Investment Management', 'Financial Planning', 'Retirement Solutions', 'Wealth Management']
  },
  // Defense and aerospace
  { 
    name: 'Lockheed Martin', 
    logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSr20jRBtn1x5pCO92DyArFotWYGEwjCcl-kA&s', 
    industry: 'Defense',
    description: 'A global leader in aerospace, defense, and advanced technology, pioneering innovations that shape the future of aviation and space exploration.',
    expertise: ['Software Engineering', 'Systems Integration', 'Aerospace Engineering', 'Cybersecurity']
  },
  { 
    name: 'L3Harris', 
    logo: 'https://cdn.prod.website-files.com/63c140cca12dc30690fce304/662bb19a95f9847c5c9bb354_L3Harris%20Technologies.svg', 
    industry: 'Defense',
    description: 'Delivering advanced defense and commercial technologies across air, land, sea, space, and cyber domains.',
    expertise: ['Defense Systems', 'Communication Systems', 'Space Technology', 'Electronic Systems']
  },
  // Major retail and consumer services
  { 
    name: 'Walmart', 
    logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSo6HInE138_03esC-wW8ZXwgIh5Wc4VpAFQQ&s', 
    industry: 'Retail',
    description: 'A multinational retail corporation operating a chain of hypermarkets, discount department stores, and grocery stores worldwide.',
    expertise: ['Retail Management', 'Supply Chain', 'E-commerce', 'Customer Experience']
  },
  { 
    name: 'GEICO', 
    logo: 'https://1000logos.net/wp-content/uploads/2018/08/logo-geico-insurance.jpg', 
    industry: 'Insurance',
    description: 'A leading auto insurance company known for its competitive rates and customer service, offering a range of insurance products.',
    expertise: ['Auto Insurance', 'Customer Service', 'Risk Assessment', 'Insurance Products']
  },
  // Manufacturing and transportation
  { 
    name: 'Michelin', 
    logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2FPM3b8FsPRrYL0KPzPviHCKObd2_sI3pdg&s', 
    industry: 'Manufacturing',
    description: 'A global leader in tire manufacturing, committed to enhancing mobility and sustainability through innovative products and services.',
    expertise: ['Tire Manufacturing', 'Sustainability', 'Mobility Solutions', 'Innovation']
  },
  { 
    name: 'CSX', 
    logo: 'https://upload.wikimedia.org/wikipedia/commons/f/fc/CSX_Corporation_logo.svg', 
    industry: 'Transportation',
    description: 'A premier transportation company providing rail-based freight transportation services across North America.',
    expertise: ['Rail Transportation', 'Logistics', 'Supply Chain Management', 'Freight Solutions']
  },
  // Other companies
  { 
    name: 'Assurant', 
    logo: 'https://pbs.twimg.com/profile_images/875062166671433728/DDPhhbBF_400x400.jpg', 
    industry: 'Insurance',
    description: 'A global provider of risk management solutions, specializing in protecting consumer purchases and supporting the connected lifestyle.',
    expertise: ['Risk Management', 'Insurance Solutions', 'Consumer Protection', 'Connected Living']
  },
  { 
    name: 'NextEra Energy', 
    logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhlYc9wUkJ02V1ihPLhVcOsm_Dx-re2cpy4g&s', 
    industry: 'Energy',
    description: 'A leading clean energy company, focusing on renewable energy generation and sustainable energy solutions.',
    expertise: ['Renewable Energy', 'Sustainable Solutions', 'Energy Efficiency', 'Power Generation']
  }
];

// Animation variants
const containerVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.3
    }
  }
};

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 100
    }
  }
};

const CompanyMentors = () => {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [hoveredCompany, setHoveredCompany] = useState(null);
  const [filter, setFilter] = useState('');
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const industries = [...new Set(companies.map(company => company.industry))];
  
  const filteredCompanies = companies.filter(company => {
    const matchesSearch = company.name.toLowerCase().includes(filter.toLowerCase()) ||
                         company.industry.toLowerCase().includes(filter.toLowerCase());
    const matchesIndustry = !selectedIndustry || company.industry === selectedIndustry;
    return matchesSearch && matchesIndustry;
  });

  const handleConnectClick = () => {
    if (currentUser) {
      navigate('/dashboard');
    } else {
      navigate('/signin');
    }
  };

  return (
    <div className="relative min-h-screen bg-gradient-to-b from-blue-950 via-blue-800 to-blue-600 overflow-hidden">
      <style jsx>{`
        @keyframes float {
          0%, 100% { transform: translate(0, 0) rotate(0deg); }
          25% { transform: translate(10px, -10px) rotate(2deg); }
          50% { transform: translate(0, -20px) rotate(0deg); }
          75% { transform: translate(-10px, -10px) rotate(-2deg); }
        }
        
        @keyframes pulse {
          0%, 100% { opacity: 0.6; transform: scale(1); }
          50% { opacity: 1; transform: scale(1.05); }
        }

        @keyframes wave {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-10px); }
        }

        .floating-ship {
          animation: float 8s infinite ease-in-out;
        }

        .pulse-effect {
          animation: pulse 4s infinite ease-in-out;
        }

        .wave-effect {
          animation: wave 6s infinite ease-in-out;
        }

        .card-gradient {
          background: linear-gradient(
            135deg,
            rgba(255, 255, 255, 0.1) 0%,
            rgba(255, 255, 255, 0.05) 100%
          );
        }

        .card-hover {
          transition: all 0.3s ease;
        }

        .card-hover:hover {
          transform: translateY(-10px);
          box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
        }

        .select-industry {
          color: white; /* Set text color to white */
          background-color: rgba(255, 255, 255, 0.1); /* Optional: Set background color */
        }

        .select-industry option {
          color: white; /* Ensure options text is white */
          background-color: rgba(0, 0, 0, 0.8); /* Optional: Set background color for options */
        }
      `}</style>

      <Navbar />
      
      {/* Background Elements */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <Waves className="absolute inset-x-0 bottom-0 z-0 opacity-20 wave-effect" />
        <Ship className="hidden md:block absolute top-[10%] left-[5%] z-10 opacity-30 w-16 h-16 md:w-24 md:h-24 floating-ship" />
        <Ship className="hidden md:block absolute top-[30%] right-[15%] z-10 opacity-20 w-12 h-12 md:w-20 md:h-20 floating-ship" />
        <Compass className="hidden md:block absolute bottom-[20%] right-[10%] z-10 opacity-30 w-16 h-16 md:w-24 md:h-24 pulse-effect" />
      </div>

      <div className="container mx-auto px-4 pt-24 md:pt-32 pb-20 relative z-10">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-8 md:mb-16"
        >
          <h1 className="text-4xl md:text-7xl font-bold text-white mb-4 md:mb-6 tracking-tight">
            Our Fleet of Mentors
          </h1>
          <p className="text-lg md:text-xl text-blue-100 max-w-2xl mx-auto">
            Navigate your career journey with guidance from industry leaders
          </p>
        </motion.div>

        {/* Search and Filter Controls */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="mb-8 flex flex-col md:flex-row gap-4 justify-center items-center"
        >
          <input
            type="text"
            placeholder="Search companies..."
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="w-full md:w-64 px-4 py-2 rounded-lg bg-white/10 border border-white/20 text-white placeholder-white/60 focus:outline-none focus:ring-2 focus:ring-white/30"
          />
          <select
            value={selectedIndustry}
            onChange={(e) => setSelectedIndustry(e.target.value)}
            className="select-industry w-full md:w-48 px-4 py-2 rounded-lg bg-white/10 border border-white/20 text-black focus:outline-none focus:ring-2 focus:ring-white/30"
          >
            <option value="">All Industries</option>
            {industries.map(industry => (
              <option key={industry} value={industry}>{industry}</option>
            ))}
          </select>
        </motion.div>

        {/* Company Grid */}
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="show"
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-8"
        >
          {filteredCompanies.map((company, index) => (
            <motion.div
              key={company.name}
              variants={itemVariants}
              className="relative"
            >
              <div
                className="card-gradient card-hover rounded-xl md:rounded-2xl p-4 md:p-6 backdrop-blur-lg border border-white/10 h-full"
                onMouseEnter={() => setHoveredCompany(company)}
                onMouseLeave={() => setHoveredCompany(null)}
                onClick={() => setSelectedCompany(company)}
              >
                <div className="relative z-10">
                  <div className="h-24 md:h-32 flex items-center justify-center mb-4">
                    <motion.img
                      src={company.logo}
                      alt={`${company.name} logo`}
                      className="max-h-full max-w-full object-contain"
                      whileHover={{ scale: 1.1 }}
                      transition={{ type: "spring", stiffness: 300 }}
                    />
                  </div>
                  <h3 className="text-lg md:text-xl font-semibold text-white mb-2">{company.name}</h3>
                  <p className="text-blue-200 text-sm">{company.industry}</p>

                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{
                      opacity: hoveredCompany === company ? 1 : 0,
                      height: hoveredCompany === company ? "auto" : 0
                    }}
                    transition={{ duration: 0.3 }}
                    className="mt-4 pt-4 border-t border-white/10 overflow-hidden"
                  >
                    <p className="text-sm text-blue-100 line-clamp-3">
                      {company.description}
                    </p>
                  </motion.div>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>

        {/* No Results Message */}
        {filteredCompanies.length === 0 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-center text-white mt-8"
          >
            <p className="text-xl">No companies found matching your criteria</p>
          </motion.div>
        )}
      </div>

      {/* Modal - Updated for better mobile experience */}
      <AnimatePresence>
        {selectedCompany && (
          <motion.div
            className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/60 backdrop-blur-sm overflow-y-auto"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setSelectedCompany(null)}
          >
            <motion.div
              className="relative w-full max-w-2xl bg-gradient-to-br from-blue-900 to-blue-950 rounded-2xl shadow-2xl max-h-[90vh] overflow-y-auto"
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              onClick={e => e.stopPropagation()}
            >
              <div className="p-8">
                <div className="flex justify-between items-start mb-6">
                  <motion.div
                    initial={{ x: -20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0.2 }}
                  >
                    <h2 className="text-3xl font-bold text-white mb-2">{selectedCompany.name}</h2>
                    <p className="text-blue-200">{selectedCompany.industry}</p>
                  </motion.div>
                  <button
                    onClick={() => setSelectedCompany(null)}
                    className="text-blue-200 hover:text-white transition-colors"
                  >
                    <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>

                <motion.div
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.3 }}
                  className="grid grid-cols-1 md:grid-cols-2 gap-8"
                >
                  <div className="bg-white/5 rounded-xl p-4">
                    <motion.img
                      src={selectedCompany.logo}
                      alt={`${selectedCompany.name} logo`}
                      className="w-full h-48 object-contain"
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: "spring", stiffness: 300 }}
                    />
                  </div>
                  <div className="space-y-4">
                    <motion.div
                      initial={{ x: -20, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ delay: 0.4 }}
                      className="bg-white/5 rounded-xl p-6"
                    >
                      <p className="text-blue-100 leading-relaxed">
                        {selectedCompany.description}
                      </p>
                    </motion.div>
                    
                    <motion.div
                      initial={{ x: -20, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ delay: 0.5 }}
                      className="bg-white/5 rounded-xl p-4"
                    >
                      <h4 className="text-white font-semibold mb-3">Areas of Expertise</h4>
                      <div className="grid grid-cols-1 gap-2">
                        {selectedCompany.expertise.map((exp, index) => (
                          <motion.div
                            key={exp}
                            initial={{ x: -20, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{ delay: 0.6 + index * 0.1 }}
                            className="flex items-center text-blue-100"
                          >
                            <Award className="w-4 h-4 mr-3 text-blue-300" />
                            <span>{exp}</span>
                          </motion.div>
                        ))}
                      </div>
                    </motion.div>
                  </div>
                </motion.div>

                <motion.div
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.8 }}
                  className="p-6 bg-gradient-to-r from-blue-900/50 to-blue-800/50 border-t border-white/10"
                >
                  <button
                    onClick={handleConnectClick}
                    className="w-full bg-blue-500 hover:bg-blue-600 text-white py-3 px-6 rounded-xl
                              transition-all duration-200 flex items-center justify-center space-x-2
                              hover:shadow-lg hover:shadow-blue-500/25"
                  >
                    <Anchor className="w-5 h-5" />
                    <span>Connect with Mentors</span>
                  </button>
                </motion.div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default CompanyMentors;
