import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { AuthProvider } from "./contexts/AuthContext";
import { BrowserRouter as Router } from "react-router-dom"; // Import BrowserRouter

ReactDOM.render(
  <AuthProvider>
      <Router> {/* Wrap App with Router */}
        <App />
      </Router>
  </AuthProvider>,
  document.getElementById("root")
);
