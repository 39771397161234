import React, { useContext, useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { AuthContext } from "../../contexts/AuthContext";
import Navbar from '../../components/Navbar/Navbar';
import { Waves } from '../LandingPage/Waves';
import { Ship } from '../LandingPage/Ship';
import { Compass } from '../LandingPage/Compass';
import { useNavigate } from 'react-router-dom';
import { Send, Zap, LogOut, Smile, User, Star, Trophy, AlertCircle, FileText, Upload } from 'lucide-react';
import { getDocument } from 'pdfjs-dist/build/pdf';
import * as pdfjsLib from 'pdfjs-dist';
import mammoth from 'mammoth';

const industries = [
    "Sustainable Energy",
    "Defense & Aerospace",
    "Healthcare & Biotech",
    "Artificial Intelligence & Machine Learning",
    "Cybersecurity",
    "Fintech",
    "Robotics & Automation",
    "Quantum Computing",
    "Space Technology",
    "Environmental Science",
    "Nanotechnology",
    "Telecommunications",
    "Automotive & Transportation",
    "Education Technology",
    "Augmented & Virtual Reality",
    "Blockchain & Cryptocurrency",
    "Internet of Things (IoT)",
    "Advanced Materials",
    "Genomics & Personalized Medicine",
    "Custom"
  ];

const loadingPhrases = [
  "Connecting to AI Mentor...",
  "Processing your information...",
  "Analyzing context...",
  "Preparing personalized response..."
];

const ShipIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="w-8 h-8 text-blue-600"
  >
    <path d="M2 21c.6.5 1.2 1 2.5 1 2.5 0 2.5-2 5-2 1.3 0 1.9.5 2.5 1 .6.5 1.2 1 2.5 1 2.5 0 2.5-2 5-2 1.3 0 1.9.5 2.5 1" />
    <path d="M19.38 20A11.6 11.6 0 0 0 21 14l-9-4-9 4c0 2.9.94 5.34 2.81 7.76" />
    <path d="M19 13V7a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v6" />
    <path d="M12 10v4" />
    <path d="M12 2v3" />
  </svg>
);

const ChatMessage = ({ message, isAI }) => (
  <div className={`p-4 rounded-xl mb-4 ${isAI ? 'bg-blue-50' : 'bg-gray-50'}`}>
    <div className="font-medium mb-2 text-sm text-gray-600">
      {isAI ? 'AI Mentor' : 'You'}
    </div>
    <div className="prose max-w-none">
      {message.split('\n').map((paragraph, index) => {
        // Enhanced formatting for better readability
        if (paragraph.startsWith('•') || paragraph.startsWith('-')) {
          return <li key={index} className="ml-4">{paragraph.substring(1).trim()}</li>;
        }
        if (paragraph.match(/^[A-Z][\w\s]+:/)) {
          return <h4 key={index} className="font-bold mt-3 mb-2">{paragraph}</h4>;
        }
        return <p key={index} className="mb-2">{paragraph}</p>;
      })}
    </div>
  </div>
);

const Dashboard = () => {
  const { currentUser, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [aiResponse, setAiResponse] = useState("");
  const [userQuestion, setUserQuestion] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingPhrase, setLoadingPhrase] = useState("");
  const responseIndexRef = useRef(0);
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [customIndustry, setCustomIndustry] = useState("");
  const [role, setRole] = useState("");
  const [company, setCompany] = useState("");
  const [showAIMentor, setShowAIMentor] = useState(false);
  const [resumeFile, setResumeFile] = useState(null);
  const [resumeData, setResumeData] = useState(null);
  const fileInputRef = useRef(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [fullConversation, setFullConversation] = useState([]);

  const handleLogout = async () => {
    await logout();
    navigate('/signin');
  };

  if (!currentUser) {
    navigate('/signin');
    return null;
  }

  const handleResumeUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Check file size (5MB limit)
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes
    if (file.size > MAX_FILE_SIZE) {
      alert('File size exceeds 5MB limit. Please choose a smaller file.');
      setResumeFile(null);
      return;
    }

    // Validate file type
    const validTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    if (!validTypes.includes(file.type)) {
      alert('Invalid file type. Please upload a PDF or Word document.');
      setResumeFile(null);
      return;
    }

    setResumeFile(file);
    try {
      const parsedData = await parseResume(file);
      if (parsedData && parsedData.text) {
        setResumeData(parsedData);
        console.log('Parsed resume data:', parsedData);
        alert('Resume parsed successfully!');
      } else {
        throw new Error('No text content found in the document.');
      }
    } catch (error) {
      console.error('Resume parsing error:', error);
      alert(`Failed to parse resume: ${error.message}`);
      setResumeFile(null);
      setResumeData(null);
    }
  };

  const parseResume = async (file) => {
    try {
      const fileType = file.type;
      let text = '';

      if (fileType === 'application/pdf') {
        text = await parsePDF(file);
      } else if (
        fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        fileType === 'application/msword'
      ) {
        text = await parseDOCX(file);
      } else {
        throw new Error('Unsupported file format');
      }

      // Validate and clean the extracted text
      if (!text || typeof text !== 'string' || text.trim().length === 0) {
        throw new Error('No readable text found in the document');
      }

      // Clean and normalize the text
      text = cleanText(text);

      return { text, fileType };
    } catch (error) {
      console.error('Error in parseResume:', error);
      throw new Error(`Failed to parse resume: ${error.message}`);
    }
  };

  const parsePDF = async (file) => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      
      // Initialize PDF.js
      pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.mjs`;

      // Load the PDF document
      const loadingTask = pdfjsLib.getDocument({ data: arrayBuffer });
      const pdf = await loadingTask.promise;

      let fullText = '';
      
      // Extract text from each page
      for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
        const page = await pdf.getPage(pageNum);
        const textContent = await page.getTextContent();
        const pageText = textContent.items
          .map(item => item.str)
          .join(' ');
        fullText += pageText + '\n';
      }

      if (!fullText.trim()) {
        throw new Error('No text content found in PDF');
      }

      return fullText;
    } catch (error) {
      console.error('Error in parsePDF:', error);
      throw new Error(`PDF parsing failed: ${error.message}`);
    }
  };

  const parseDOCX = async (file) => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const result = await mammoth.extractRawText({ arrayBuffer });
      
      if (!result.value.trim()) {
        throw new Error('No text content found in DOCX');
      }

      return result.value;
    } catch (error) {
      console.error('Error in parseDOCX:', error);
      throw new Error(`DOCX parsing failed: ${error.message}`);
    }
  };

  const cleanText = (text) => {
    return text
      // Replace multiple spaces with a single space
      .replace(/\s+/g, ' ')
      // Replace multiple newlines with a single newline
      .replace(/[\n\r]+/g, '\n')
      // Remove special characters and control characters
      .replace(/[\x00-\x1F\x7F-\x9F]/g, '')
      // Remove any remaining non-printable characters
      .replace(/[^\x20-\x7E\n]/g, '')
      // Trim whitespace from the beginning and end
      .trim();
  };

  // Add this function to validate the parsed content
  const validateParsedContent = (text) => {
    if (!text || typeof text !== 'string') {
      return false;
    }
    
    // Check if the text has a minimum length
    const MIN_LENGTH = 50; // Adjust this value based on your needs
    if (text.trim().length < MIN_LENGTH) {
      return false;
    }

    // Check if the text contains some common resume keywords
    const commonKeywords = ['experience', 'education', 'skills', 'work', 'project'];
    const hasKeywords = commonKeywords.some(keyword => 
      text.toLowerCase().includes(keyword)
    );

    return hasKeywords;
  };

  // Update the file input component to show more detailed status
  const FileUploadComponent = ({ onFileSelect, currentFile, isLoading }) => (
    <div className="space-y-2">
      <label className="text-sm font-semibold text-gray-700">
        Upload Your Resume
      </label>
      <div 
        className={`relative border-2 border-dashed rounded-xl p-6 transition-colors duration-200 cursor-pointer
          ${isLoading ? 'border-yellow-300 bg-yellow-50' : 
            currentFile ? 'border-green-300 bg-green-50' : 
            'border-gray-300 hover:border-blue-500'}`}
        onClick={() => document.getElementById('resume-upload').click()}
      >
        <input
          id="resume-upload"
          type="file"
          className="hidden"
          onChange={onFileSelect}
          accept=".pdf,.doc,.docx"
        />
        <div className="flex flex-col items-center justify-center space-y-3">
          <Upload className={`w-8 h-8 ${isLoading ? 'text-yellow-400' : currentFile ? 'text-green-400' : 'text-gray-400'}`} />
          <div className="text-center">
            {isLoading ? (
              <p className="text-yellow-600">Processing resume...</p>
            ) : currentFile ? (
              <div className="flex flex-col items-center">
                <span className="text-green-500 font-medium">{currentFile.name}</span>
                <span className="text-sm text-green-600">✓ Successfully parsed</span>
              </div>
            ) : (
              <>
                <p className="text-blue-500 font-medium">Click to upload or drag and drop</p>
                <p className="text-sm text-gray-500">PDF or Word documents (Max 5MB)</p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const generateInitialPrompt = (industry, role, company, resumeData) => {
    let prompt = `You are an experienced AI Career Mentor specializing in ${industry}`;
    if (role) prompt += ` with deep expertise as a ${role}`;
    if (company) prompt += ` and practical knowledge from working at ${company}`;
    
    prompt += `.\n\nMentor Personality Guidelines:
- Be warm and encouraging, but maintain professionalism
- Use a conversational, natural tone (avoid formal email-style language)
- Show genuine interest in the mentee's background and goals
- Share insights from your industry perspective
- Be direct and honest in your guidance

Communication Style:
- Use natural transitions between topics
- Include occasional light professional humor when appropriate
- Address the mentee directly using "you" statements
- Break complex topics into digestible insights
- Use industry-specific examples to illustrate points

Response Structure:
- Start with a warm, personalized greeting
- Share a brief relevant insight or observation
- Present advice in a clear, actionable format
- End with an engaging question to continue the dialogue

`;

    if (resumeData) {
      prompt += `Context from mentee's background:\n${resumeData.text}\n\n`;
    }

    prompt += `For your first response:
1. Begin with a warm, personalized introduction that references their industry
2. Share one specific insight about their field or background (if resume provided)
3. Offer one piece of actionable advice related to their industry
4. Ask a focused question to understand their immediate career goals or challenges

Keep your tone conversational but professional, as if speaking in a one-on-one mentoring session. Avoid corporate jargon unless specifically relevant to their industry.`;

    return prompt;
  };

  const handleAIMentorSubmit = async (e) => {
    e.preventDefault();
    const industry = selectedIndustry === "Custom" ? customIndustry : selectedIndustry;
    if (!industry) {
      alert("Please select or enter an industry.");
      return;
    }

    setIsLoading(true);
    setShowAIMentor(false);
    setLoadingPhrase(loadingPhrases[0]);

    try {
      // Parse resume if provided
      let parsedResumeData = null;
      if (resumeFile) {
        console.log("Parsing resume...");
        parsedResumeData = await parseResume(resumeFile);
        console.log("Resume parsed successfully:", parsedResumeData);
        setResumeData(parsedResumeData);
      }

      // Generate initial prompt
      const initialPrompt = generateInitialPrompt(industry, role, company, parsedResumeData);
      console.log("Initial prompt generated:", initialPrompt);

      // Update loading phrase
      setLoadingPhrase(loadingPhrases[1]);

      // API call using OpenRouter
      const response = await fetch("https://openrouter.ai/api/v1/chat/completions", {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${process.env.REACT_APP_OPENROUTER_API_KEY}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "model": "nousresearch/hermes-3-llama-3.1-405b:free",
          "messages": [{ role: 'user', content: initialPrompt }]
        })
      });

      const data = await response.json();
      const aiResponseText = data.choices[0].message.content;

      // Update chat history with only the AI's response
      setChatHistory([
        { role: 'assistant', content: aiResponseText }
      ]);

      setIsLoading(false);
      setShowAIMentor(true);
      setAiResponse(aiResponseText);
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
      alert(`Failed to connect to AI Mentor. Error: ${error.message || error}`);
    }
  };

  const handleSendQuestion = async () => {
    if (userQuestion.trim() === "") return;

    setIsLoading(true);
    setLoadingPhrase(loadingPhrases[2]);

    try {
      // Add user's new question to chat history
      const updatedHistory = [
        ...chatHistory,
        { role: 'user', content: userQuestion }
      ];

      // API call using OpenRouter
      const response = await fetch("https://openrouter.ai/api/v1/chat/completions", {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${process.env.REACT_APP_OPENROUTER_API_KEY}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "model": "nousresearch/hermes-3-llama-3.1-405b:free",
          "messages": updatedHistory
        })
      });

      const data = await response.json();
      const aiResponseText = data.choices[0].message.content;
      
      // Update chat history with AI's response
      setChatHistory([
        ...updatedHistory,
        { role: 'assistant', content: aiResponseText }
      ]);

      setIsLoading(false);
      setAiResponse(aiResponseText);
      setUserQuestion("");
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
      alert('Failed to get AI response. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 via-blue-100 to-blue-200 relative overflow-hidden">
      <Navbar />
      <Waves className="absolute bottom-0 left-0 w-full opacity-30" />
      <Ship className="absolute bottom-10 right-10 w-32 h-32 animate-bounce-slow opacity-20" />
      <Compass className="absolute top-20 left-10 w-24 h-24 animate-spin-slow opacity-20" />
      
      <div className="container mx-auto px-4 py-8 mt-20">
        <motion.div 
          className="max-w-3xl mx-auto bg-white/90 backdrop-blur-lg p-8 rounded-2xl shadow-xl"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-3xl font-bold mb-6 text-blue-800 flex items-center">
            <ShipIcon />
            <span className="ml-3">AI Mentor Navigator</span>
          </h2>
          
          <form onSubmit={handleAIMentorSubmit} className="space-y-6">
            {/* Industry Selection */}
            <div className="space-y-2">
              <label className="text-sm font-semibold text-gray-700">
                Select Your Industry
              </label>
              <select
                value={selectedIndustry}
                onChange={(e) => setSelectedIndustry(e.target.value)}
                className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
              >
                <option value="">Choose an industry...</option>
                {industries.map((industry) => (
                  <option key={industry} value={industry}>{industry}</option>
                ))}
              </select>
            </div>

            {/* Custom Industry Input */}
            {selectedIndustry === "Custom" && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                className="space-y-2"
              >
                <label className="text-sm font-semibold text-gray-700">
                  Specify Your Industry
                </label>
                <input
                  type="text"
                  value={customIndustry}
                  onChange={(e) => setCustomIndustry(e.target.value)}
                  className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                  placeholder="E.g., Space Tourism"
                />
              </motion.div>
            )}

            {/* Role and Company Inputs */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="space-y-2">
                <label className="text-sm font-semibold text-gray-700">
                  Your Role (Optional)
                </label>
                <input
                  type="text"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                  placeholder="E.g., Software Engineer"
                />
              </div>
              
              <div className="space-y-2">
                <label className="text-sm font-semibold text-gray-700">
                  Company (Optional)
                </label>
                <input
                  type="text"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                  placeholder="E.g., SpaceX"
                />
              </div>
            </div>

            {/* Resume Upload */}
            <div className="space-y-2">
              <label className="text-sm font-semibold text-gray-700">
                Upload Your Resume
              </label>
              <div 
                className="relative border-2 border-dashed border-gray-300 rounded-xl p-6 hover:border-blue-500 transition-colors duration-200 cursor-pointer"
                onClick={() => fileInputRef.current?.click()}
              >
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleResumeUpload}
                  className="hidden"
                  accept=".pdf,.doc,.docx"
                />
                <div className="flex flex-col items-center justify-center space-y-3">
                  <Upload className="w-8 h-8 text-gray-400" />
                  <div className="text-center">
                    <p className="text-gray-600">
                      {resumeFile ? (
                        <div className="flex flex-col items-center">
                          <span className="text-blue-500 font-medium">{resumeFile.name}</span>
                          <div className="flex space-x-2 mt-2">
                            <span 
                              className="text-sm text-gray-500 hover:text-gray-700 cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                fileInputRef.current?.click();
                              }}
                            >
                              Change file
                            </span>
                            <span className="text-gray-300">|</span>
                            <span 
                              className="text-sm text-red-500 hover:text-red-700 cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                setResumeFile(null);
                                setResumeData(null);
                              }}
                            >
                              Remove
                            </span>
                          </div>
                        </div>
                      ) : (
                        <>
                          <span className="text-blue-500 font-medium">Click to upload</span> or drag and drop
                          <br />
                          <span className="text-sm text-gray-500">PDF, DOC, DOCX (Max 5MB)</span>
                        </>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full bg-gradient-to-r from-blue-500 to-blue-600 text-white px-6 py-3 rounded-xl hover:from-blue-600 hover:to-blue-700 transition-all duration-200 flex items-center justify-center space-x-2 shadow-lg hover:shadow-xl disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={!selectedIndustry || (selectedIndustry === "Custom" && !customIndustry)}
            >
              <Zap className="w-5 h-5" />
              <span>Connect to AI Mentor</span>
            </button>
          </form>

          {/* Loading State */}
          <AnimatePresence>
            {isLoading && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="mt-6 bg-blue-50 p-6 rounded-xl"
              >
                <div className="flex items-center justify-center space-x-3">
                  <div className="w-3 h-3 bg-blue-500 rounded-full animate-pulse"></div>
                  <div className="w-3 h-3 bg-blue-500 rounded-full animate-pulse delay-75"></div>
                  <div className="w-3 h-3 bg-blue-500 rounded-full animate-pulse delay-150"></div>
                </div>
                <p className="text-center text-blue-800 mt-3 font-medium">{loadingPhrase}</p>
              </motion.div>
            )}
          </AnimatePresence>

          {/* AI Response Section */}
          <AnimatePresence>
            {showAIMentor && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
                className="mt-6 space-y-4"
              >
                <div className="max-h-[600px] overflow-y-auto space-y-4">
                  {chatHistory.map((msg, index) => (
                    <ChatMessage
                      key={index}
                      message={msg.content}
                      isAI={msg.role === 'assistant'}
                    />
                  ))}
                  
                  {isLoading && (
                    <div className="bg-blue-50 p-4 rounded-xl">
                      <div className="flex items-center space-x-3">
                        <div className="w-2 h-2 bg-blue-500 rounded-full animate-pulse"></div>
                        <div className="w-2 h-2 bg-blue-500 rounded-full animate-pulse delay-75"></div>
                        <div className="w-2 h-2 bg-blue-500 rounded-full animate-pulse delay-150"></div>
                      </div>
                      <p className="text-sm text-blue-800 mt-2">{loadingPhrase}</p>
                    </div>
                  )}
                </div>

                <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
                  <p className="text-sm font-medium text-gray-700 mb-3">Ask your AI Mentor:</p>
                  <textarea
                    className="w-full p-4 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                    rows={3}
                    placeholder="What would you like to learn about?"
                    value={userQuestion}
                    onChange={(e) => setUserQuestion(e.target.value)}
                  ></textarea>
                  <button 
                    type="button"
                    className="mt-3 bg-green-500 text-white px-6 py-3 rounded-xl hover:bg-green-600 transition-colors flex items-center justify-center space-x-2"
                    onClick={handleSendQuestion}
                    disabled={!userQuestion.trim() || isLoading}
                  >
                    <Send className="w-5 h-5" />
                    <span>Send Question</span>
                  </button>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </div>
    </div>
  );
};

export default Dashboard;
