// Import the functions you need from the SDKs you need
import firebase from "firebase";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAfFzc4B9iIsCzcMycMjcegQYiWAnIzs4M",
  authDomain: "mentorship-f2f9c.firebaseapp.com",
  projectId: "mentorship-f2f9c",
  storageBucket: "mentorship-f2f9c.appspot.com",
  messagingSenderId: "790822188498",
  appId: "1:790822188498:web:55c017c23ebd7d6c35440b",
  measurementId: "G-J2BZ1DPF2J"
};

// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const db = firebase.firestore();
export const FieldValue = firebase.firestore.FieldValue;